import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Agendamento } from "../model/agendamento";
import { Login } from "../model/login";
import * as _ from "lodash";
import { tap, catchError } from "rxjs/operators";
import { AgendamentoNew } from "../model/agendamentoNew";
import { ToastController } from "@ionic/angular";

@Injectable({
  providedIn: "root",
})
export class AgendamentoService {
  constructor(
    private http: HttpClient,
    private toastController: ToastController
  ) { }

  public consultaDiasDisponiveis(idParceiro: any) {
    return this.http
      .get<any[]>(
        `${environment.apiUrl}agendamento/disponibilidade/${idParceiro}`
      )
      .toPromise();
  }

  public consultarHorariosDisponiveis(horariosDisponiveisRequest: any) {
    return this.http
      .post<any[]>(
        `${environment.apiUrl}agendamento/disponibilidade/`,
        horariosDisponiveisRequest
      )
      .toPromise();
  }

  public async consultarParceirosDisponveis(duracaoHorarioFiltro: any, horarioFiltro: any, dataFiltro: any) {

    let objetoFiltro = {
      "duracaoHorarioFiltro": duracaoHorarioFiltro,
      "horarioFiltro": horarioFiltro,
      "dataFiltro": dataFiltro
    }

    return this.http
      .post<any[]>(
        `${environment.apiUrl}campo/filtrar/`,
        objetoFiltro
      )
      .toPromise();
  }

  public async buscarValorReserva(buscarValorRequest: any) {
    return this.http
      .get<any[]>(
        `${environment.apiUrl}agendamento/valorDefault/${buscarValorRequest.dataAgendamento}/${buscarValorRequest.idParceiro}/${buscarValorRequest.idCampo}/${buscarValorRequest.duracaoPartida}/${buscarValorRequest.idTipoAgendamento}/${buscarValorRequest.horarioPartida}`
      )
      .toPromise();
  }

  /* lista dos agendamentos */
  getAgendamentos(idEmpresa: string) {
    return this.http.get<Agendamento[]>(
      `${environment.apiUrl}task/filter/all/${idEmpresa}`
    );
  }

  /*lista dos horarios ocupados*/
  getHorariosOcupados(idEmpresa: number) {
    return this.http.get<Agendamento[]>(
      `${environment.apiUrl}task/time/${idEmpresa}`
    );
  }

  /*lista dos horarios ocupados*/
  getHorariosOcupadosPromise(idEmpresa: number) {
    return this.http
      .get<Agendamento[]>(`${environment.apiUrl}task/time/${idEmpresa}`)
      .toPromise();
  }

  getVerificaDisponibilidadeMensal(agenda: Agendamento) {
    return this.http
      .get(`${environment.apiUrl}task/filter/checkdate/${agenda.idempresa}`, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          "No-Auth": "True",
          data: agenda.when,
          tipoHora: agenda.tipoHora.toString(),
          nomecampo: agenda.nomecampo.toString(),
        }),
      })
      .pipe(
        catchError((error) => {
          return error;
        })
      );
  }

  //Cadastra agendamentos
  async cadastrarAgendamento(agendamento: AgendamentoNew) {
    var usuario: Login;
    usuario = JSON.parse(localStorage.getItem("login"));
    localStorage.removeItem("confirmacaoAgendamento");
    return this.http
      .post(`${environment.apiUrl}agendamento`, agendamento)
      .toPromise();
  }

  //Autorizacoes
  autorizacao(user, tipo) {
    let autorizacaoBasicAutentication =
      "Basic " + btoa(user.email + ":" + user.senha);
    let autorizacaoBaererToken = `Bearer ${user.token}`;

    if (tipo == 1) {
      const headerOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: autorizacaoBasicAutentication,
        }),
      };

      return headerOptions;
    } else if (tipo == 2) {
      const headerOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: autorizacaoBaererToken,
          type: "1",
        }),
      };

      return headerOptions;
    } else {
      const headerOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      };
      return headerOptions;
    }
  }

  async sucesso(message) {
    var a = this.toastController;
    const msg = await a.create({
      message: message,
      duration: 3000,
      color: "primary",
    });
    msg.present();
  }

  async falha(erro) {
    var a = this.toastController;
    const msg = await a.create({
      message: erro,
      position: "top",
      duration: 3000,
      color: "danger",
    });
    msg.present();
  }
}
