import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild,
} from "@angular/router";
import { NavController } from "@ionic/angular";

@Injectable({ providedIn: "root" })
export class PrimeiraVezGuard implements CanActivateChild {
  constructor(private navCtrl: NavController) {}

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let permiss = JSON.parse(localStorage.getItem("permissao"));

    if (permiss) {
      this.navCtrl.navigateRoot(["/home"]);
      return true;
    } else {
      localStorage.clear();
      return true;
    }
  }
}
