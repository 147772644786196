import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {
  ModalController,
  NavController,
  NavParams,
  ToastController,
} from "@ionic/angular";
import * as moment from "moment";
import { Agendamento } from "src/app/model/agendamento";
import { AgendamentoOld } from "src/app/model/agendamentoOld";
import { Usuario } from "src/app/model/usuario";
import { AgendamentoService } from "src/app/services/agendamento.service";
import { AgendamentoUtil } from "src/app/util/AgendamentoUtil";
import { DateUtil } from "src/app/util/DateUtil";

@Component({
  selector: "app-confirmacao-reserva",
  templateUrl: "./confirmacao-reserva.page.html",
  styleUrls: ["./confirmacao-reserva.page.scss"],
})
export class ConfirmacaoReservaPage implements OnInit {
  solicitacaoAgendamento: Agendamento;
  agendaUtil: AgendamentoUtil;
  horaTermino: string;
  nomeCampo: String;
  usuario: Usuario;
  dataReserva: any;
  pix: any;
  anticipatedPayment: boolean;
  telefoneCampo: any;
  confirmacaoFeita: boolean = false;

  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
    private agendamentoService: AgendamentoService,
    private router: Router,
    private navCtrl: NavController,
    private toastCtrl: ToastController
  ) {
    this.usuario = JSON.parse(localStorage.getItem("data"));
    this.solicitacaoAgendamento = this.navParams.get("solicitacaoAgendamento");

    this.pix = this.solicitacaoAgendamento.pix
      ? (this.pix = this.solicitacaoAgendamento.pix)
      : { nome: "", chave: "" };

    this.anticipatedPayment = this.navParams.get("anticipatedPayment")
      ? this.navParams.get("anticipatedPayment")
      : this.solicitacaoAgendamento.anticipatedPayment;
    this.nomeCampo = this.navParams.get("nomeCampo");
    this.telefoneCampo = this.navParams.get("telefoneCampo");
  }

  ngOnInit() {
    this.horaTermino = AgendamentoUtil.getHoraTermino(
      this.verificarHoraTermino(),
      this.solicitacaoAgendamento.tipoHora
    );

    this.dataReserva = this.solicitacaoAgendamento.when.substring(0, 10);
    this.dataReserva =
      this.dataReserva.substring(8, 10) +
      "/" +
      this.dataReserva.substring(5, 7) +
      "/" +
      this.dataReserva.substring(0, 4);
  }

  verificarHoraTermino() {
    let index = this.solicitacaoAgendamento.when.indexOf(":");
    if (index == 12)
      return "0".concat(this.solicitacaoAgendamento.when.substring(11, 15));
    else return this.solicitacaoAgendamento.when.substring(11, 16);
  }

  verificarHoraInicial() {
    let index = this.solicitacaoAgendamento.when.indexOf(":");
    if (index == 12) return this.solicitacaoAgendamento.when.substring(11, 15);
    else return this.solicitacaoAgendamento.when.substring(11, 16);
  }

  closeModal() {
    localStorage.removeItem("confirmacaoAgendamento");
    this.modalController.dismiss();
  }

  getTipoReserva() {
    return AgendamentoUtil.getTipodeReserva(
      this.solicitacaoAgendamento.tipoAgendamento
    );
  }

  getDataReserva() {
    let dataReserva = this.solicitacaoAgendamento.when.substring(0, 10);
    return (
      dataReserva.substring(8, 10) +
      "/" +
      dataReserva.substring(5, 7) +
      "/" +
      dataReserva.substring(0, 4)
    );
  }

  async salvarAgendamento() {
    let agendamento = this.carregaAgendamentoNovo(this.solicitacaoAgendamento);

    this.confirmacaoFeita = true;

    if (this.usuario != null) {
      /*Agendamento diário */
      if (agendamento.idTipoAgendamento == 1) {
        await this.agendamentoService.cadastrarAgendamento(agendamento).then(
          async (sucess) => {
            let message = "Ihuu, agendamento realzado com sucesso.";

            const msg = await this.toastCtrl.create({
              message: message,
              duration: 3000,
              color: "primary",
            });
            msg.present();

            this.modalController.dismiss();
            this.navCtrl.navigateForward("lista/reservas");
          },
          async (error) => {
            let message = error.error.message;

            const msg = await this.toastCtrl.create({
              message: message,
              duration: 3000,
              color: "danger",
            });
            msg.present();

            this.confirmacaoFeita = false;
          }
        );
      } else if (agendamento.idTipoAgendamento == 2) {
        /*Agendamento mensal */
        let dataDoAgendamentoSolicitado = AgendamentoUtil.dateIsoStringToDate(
          agendamento.when
        );
        let arrayDatasAgendamentoMensal = new Array();
        let arrayVerificador = new Array();

        //determina a quantidade de datas para o agendamento mensal
        let cont1 =
          AgendamentoUtil.qttdSemanasDoMes(
            dataDoAgendamentoSolicitado.getMonth(),
            dataDoAgendamentoSolicitado.getFullYear()
          ) == 5
            ? 4
            : 3;

        let cont2 =
          AgendamentoUtil.qttdSemanasDoMes(
            dataDoAgendamentoSolicitado.getMonth() + 1,
            dataDoAgendamentoSolicitado.getFullYear()
          ) == 5
            ? 4
            : 3;

        for (var i = 0; cont1 + cont2 >= i; i++) {
          let novaData = moment(dataDoAgendamentoSolicitado, "DD-MM-YYYY").add(
            7 * i,
            "days"
          );

          arrayDatasAgendamentoMensal.push(
            novaData.format("DD-MM-YYYY HH:mm:ss")
          );
        }

        arrayVerificador = this.adicionaDetalhes(
          dataDoAgendamentoSolicitado,
          agendamento,
          cont1 + cont2
        );

        let resultado = await this.verificaDisponibilidadeDatas(
          arrayVerificador,
          agendamento
        );

        if (resultado) {
          for (let i = 0; i < arrayDatasAgendamentoMensal.length; i++) {
            agendamento.when = AgendamentoUtil.dateStringToIso(
              arrayDatasAgendamentoMensal[i]
            );

            await this.agendamentoService
              .cadastrarAgendamento(agendamento)
              .then(
                (data) => {
                  console.log(data);
                },
                (error) => {
                  console.log(`Deu erro: ${error}`);
                }
              );
          }
          this.sucesso();
        } else {
          this.falha();
        }
      }
    }
  }

  adicionaDetalhes(dataAgendamento, agendamento, contador) {
    let arrayDatasAgendamentoMensal = new Array();

    for (var i = 0; contador >= i; i++) {
      let novaData = moment(dataAgendamento, "DD-MM-YYYY").add(7 * i, "days");

      if (agendamento.tipoHora == 0) {
        let novaData1 = moment(novaData, "DD-MM-YYYY").add(30, "minutes");
        let novaData2 = moment(novaData, "DD-MM-YYYY").add(60, "minutes");
        let novaData3 = moment(novaData, "DD-MM-YYYY").add(90, "minutes");

        let novaData4 = moment(novaData, "DD-MM-YYYY").subtract(30, "minutes");
        let novaData5 = moment(novaData, "DD-MM-YYYY").subtract(60, "minutes");
        let novaData6 = moment(novaData, "DD-MM-YYYY").subtract(90, "minutes");

        arrayDatasAgendamentoMensal.push(
          novaData.format("DD-MM-YYYY HH:mm:ss")
        );
        arrayDatasAgendamentoMensal.push(
          novaData1.format("DD-MM-YYYY HH:mm:ss")
        );
        arrayDatasAgendamentoMensal.push(
          novaData2.format("DD-MM-YYYY HH:mm:ss")
        );
        arrayDatasAgendamentoMensal.push(
          novaData3.format("DD-MM-YYYY HH:mm:ss")
        );
        arrayDatasAgendamentoMensal.push(
          novaData4.format("DD-MM-YYYY HH:mm:ss")
        );
        arrayDatasAgendamentoMensal.push(
          novaData5.format("DD-MM-YYYY HH:mm:ss")
        );
        arrayDatasAgendamentoMensal.push(
          novaData6.format("DD-MM-YYYY HH:mm:ss")
        );
      } else {
        let novaData1 = moment(novaData, "DD-MM-YYYY").add(30, "minutes");
        let novaData2 = moment(novaData, "DD-MM-YYYY").add(60, "minutes");
        let novaData3 = moment(novaData, "DD-MM-YYYY").add(90, "minutes");
        let novaData4 = moment(novaData, "DD-MM-YYYY").add(120, "minutes");

        let novaData5 = moment(novaData, "DD-MM-YYYY").subtract(30, "minutes");
        let novaData6 = moment(novaData, "DD-MM-YYYY").subtract(60, "minutes");
        let novaData7 = moment(novaData, "DD-MM-YYYY").subtract(90, "minutes");
        let novaData8 = moment(novaData, "DD-MM-YYYY").subtract(120, "minutes");

        arrayDatasAgendamentoMensal.push(
          novaData.format("DD-MM-YYYY HH:mm:ss")
        );
        arrayDatasAgendamentoMensal.push(
          novaData1.format("DD-MM-YYYY HH:mm:ss")
        );
        arrayDatasAgendamentoMensal.push(
          novaData2.format("DD-MM-YYYY HH:mm:ss")
        );
        arrayDatasAgendamentoMensal.push(
          novaData3.format("DD-MM-YYYY HH:mm:ss")
        );
        arrayDatasAgendamentoMensal.push(
          novaData4.format("DD-MM-YYYY HH:mm:ss")
        );
        arrayDatasAgendamentoMensal.push(
          novaData5.format("DD-MM-YYYY HH:mm:ss")
        );
        arrayDatasAgendamentoMensal.push(
          novaData6.format("DD-MM-YYYY HH:mm:ss")
        );
        arrayDatasAgendamentoMensal.push(
          novaData7.format("DD-MM-YYYY HH:mm:ss")
        );
        arrayDatasAgendamentoMensal.push(
          novaData8.format("DD-MM-YYYY HH:mm:ss")
        );
      }
    }

    return arrayDatasAgendamentoMensal;
  }

  async verificaDisponibilidadeDatas(arrayDatas: any[], agendamento) {
    let resultado: boolean = true;

    await this.agendamentoService
      .getHorariosOcupadosPromise(agendamento.idempresa)
      .then((data) => {
        if (data) {
          let datasAjustadas = new Array();

          data.forEach((x) => {
            datasAjustadas.push(
              moment.utc(x.when).format("DD-MM-YYYY HH:mm:ss")
            );
          });

          datasAjustadas.sort();

          for (let i = 0; i < data.length; i++) {
            if (data[i].nomecampo === agendamento.nomecampo) {
              let dataDisponivel: boolean;
              dataDisponivel = arrayDatas.includes(datasAjustadas[i]);

              if (dataDisponivel) {
                resultado = false;
                return resultado;
              }
            }
          }
        }
      });

    return resultado;
  }

  async sucesso() {
    this.closeModal();
    this.router.navigateByUrl("/lista/reservas");
    const msg = await this.toastCtrl.create({
      message: "Reserva solicitada com sucesso!",
      duration: 3000,
      color: "primary",
      position: "top",
    });
    msg.present();
  }
  async falha() {
    this.closeModal();
    const msg = await this.toastCtrl.create({
      message: "O horário desejado já foi reservado por outro usuário!",
      duration: 3000,
      color: "danger",
      position: "top",
    });
    msg.present();
  }

  carregaAgendamento(agenda: Agendamento) {
    var agendamento: AgendamentoOld;
    agendamento = agenda;
    return agendamento;
  }

  carregaAgendamentoNovo(agenda: Agendamento) {
    let novoAgendamento = {} as any;
    let usuario : Usuario = JSON.parse(localStorage.getItem("data"));

    novoAgendamento.idCampo = agenda.idcampo;
    novoAgendamento.duracao = agenda.duracao;
    novoAgendamento.dataAgendamento = DateUtil.dateFormatterAmericaBrazil(
      agenda.when
    );
    novoAgendamento.idTipoAgendamento = 1;
    novoAgendamento.valorReserva = agenda.price;
    novoAgendamento.idParceiro = agenda.idempresa;
    novoAgendamento.hora = this.verificarHoraInicial();
    novoAgendamento.canal = 2;
    novoAgendamento.idUsuario = usuario.id;

    return novoAgendamento;
  }

  public diaDaSemana(data) {
    return DateUtil.diaDaSemana(data);
  }
}
