import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./helpers/auth.guard";
import { PrimeiraVezGuard } from "./helpers/primeira-vez.guard";

const routes: Routes = [
  {
    path: "",
    redirectTo: "permissao-localizacao",
    pathMatch: "full",
  },
  {
    path: "mobile",
    redirectTo: "permissao-localizacao",
    pathMatch: "prefix",
  },
  {
    path: "cadastro",
    loadChildren: () =>
      import("./pages/sistema/cadastro/cadastro.module").then(
        (m) => m.CadastroPageModule
      ),
  },
  {
    path: "favoritos",
    loadChildren: () =>
      import("./pages/sistema-usuario/favoritos/favoritos.module").then(
        (m) => m.FavoritosPageModule
      ),
    canActivateChild: [AuthGuard],
  },
  {
    path: "filtro",
    loadChildren: () =>
      import("./pages/sistema/filtro/filtro.module").then(
        (m) => m.FiltroPageModule
      ),
  },
  {
    path: "login",
    loadChildren: () =>
      import("./pages/sistema/login/login.module").then(
        (m) => m.LoginPageModule
      ),
  },
  {
    path: "perfil",
    loadChildren: () =>
      import("./pages/sistema-usuario/perfil/perfil.module").then(
        (m) => m.PerfilPageModule
      ),
    canActivateChild: [AuthGuard],
  },
  {
    path: "lista/reservas",
    loadChildren: () =>
      import(
        "./pages/sistema-usuario/lista-reservas/lista-reservas.module"
      ).then((m) => m.ListaReservasPageModule),
    canActivateChild: [AuthGuard],
  },
  {
    path: "home",
    loadChildren: () =>
      import("./pages/sistema/home/home.module").then((m) => m.HomePageModule),
  },
  {
    path: "informacoes/campo",
    loadChildren: () =>
      import("./pages/sistema/informacoes-parceiro/informacoes-parceiro.module").then(
        (m) => m.InformacoesCampoPageModule
      ),
  },
  {
    path: "reserva/campo",
    loadChildren: () =>
      import("./pages/sistema/reserva-campo/reserva-campo.module").then(
        (m) => m.ReservaCampoPageModule
      ),
  },
  {
    path: "confirmacao-reserva",
    loadChildren: () =>
      import(
        "./pages/sistema-usuario/confirmacao-reserva/confirmacao-reserva.module"
      ).then((m) => m.ConfirmacaoReservaPageModule),
  },
  {
    path: "localizacao/usuario",
    loadChildren: () =>
      import(
        "./pages/sistema/localizacao-usuario/localizacao-usuario.module"
      ).then((m) => m.LocalizacaoUsuarioPageModule),
  },
  {
    path: "detalhe/campo",
    loadChildren: () =>
      import("./pages/sistema/detalhe-campo/detalhe-campo.module").then(
        (m) => m.DetalheCampoPageModule
      ),
  },
  {
    path: "permissao-localizacao",
    loadChildren: () =>
      import(
        "./pages/sistema/permissao-localizacao/permissao-localizacao.module"
      ).then((m) => m.PermissaoLocalizacaoPageModule),
    canActivateChild: [PrimeiraVezGuard],
  },
  {
    path: "modal-localizacao",
    loadChildren: () =>
      import("./pages/sistema/modal-localizacao/modal-localizacao.module").then(
        (m) => m.ModalLocalizacaoPageModule
      ),
  },
  {
    path: "listar-partidas",
    loadChildren: () =>
      import(
        "./pages/sistema-usuario/gerador-times/listar-partidas/listar-partidas.module"
      ).then((m) => m.ListarPartidasPageModule),
    canActivateChild: [AuthGuard],
  },
  {
    path: "criar-partida",
    loadChildren: () =>
      import(
        "./pages/sistema-usuario/gerador-times/criar-partida/criar-partida.module"
      ).then((m) => m.CriarPartidaPageModule),
  },
  {
    path: "listar-jogadores",
    loadChildren: () =>
      import(
        "./pages/sistema-usuario/gerador-times/listar-jogadores/listar-jogadores.module"
      ).then((m) => m.ListarJogadoresPageModule),
  },
  {
    path: "adicionar-jogador",
    loadChildren: () =>
      import(
        "./pages/sistema-usuario/gerador-times/adicionar-jogador/adicionar-jogador.module"
      ).then((m) => m.AdicionarJogadorPageModule),
  },
  {
    path: "editar-excluir-jogador",
    loadChildren: () =>
      import(
        "./pages/sistema-usuario/gerador-times/editar-excluir-jogador/editar-excluir-jogador.module"
      ).then((m) => m.EditarExcluirJogadorPageModule),
  },
  {
    path: "editar-excluir-partida",
    loadChildren: () =>
      import(
        "./pages/sistema-usuario/gerador-times/editar-excluir-partida/editar-excluir-partida.module"
      ).then((m) => m.EditarExcluirPartidaPageModule),
  },
  {
    path: "exibir-resultado-geracao-time",
    loadChildren: () =>
      import(
        "./pages/sistema-usuario/gerador-times/exibir-resultado-geracao-times/exibir-resultado-geracao-times.module"
      ).then((m) => m.ExibirResultadoGeracaoTimesPageModule),
  },
  {
    path: "resultado-geracao-times",
    loadChildren: () =>
      import(
        "./pages/sistema-usuario/gerador-times/exibir-resultado-geracao-times/exibir-resultado-geracao-times.module"
      ).then((m) => m.ExibirResultadoGeracaoTimesPageModule),
  },
  {
    path: "error",
    loadChildren: () =>
      import("./pages/sistema/error/error.module").then(
        (m) => m.ErrorPageModule
      ),
  },
  {
    path: 'confirmacao-cadastro',
    loadChildren: () => import('./pages/sistema/confirmacao-cadastro/confirmacao-cadastro.module').then( m => m.ConfirmacaoCadastroPageModule)
  },
  {
    path: 'esqueci-minha-senha',
    loadChildren: () => import('./pages/sistema/esqueci-minha-senha/esqueci-minha-senha.module').then( m => m.EsqueciMinhaSenhaPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})

export class AppRoutingModule {}
