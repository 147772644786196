import * as moment from 'moment';

export class DateUtil {

    constructor() {
        moment.locale('pt-br');
      }

    private static nomeDiaDaSemana(index:number){
        if(index ==0) return 'Domingo';
        else if (index==1) return 'Segunda-feira';
        else if (index==2) return 'Terça-feira';
        else if (index==3) return 'Quarta-feira';
        else if (index==4) return 'Quinta-feira';
        else if (index==5) return 'Sexta-feira';
        else return 'Sábado';
    }

    public static diaDaSemana(data: any) : string {
        const date = moment(this.dateFormatterAmerica(data));
        return this.nomeDiaDaSemana(date.day());
    }

    public static dateFormatterAmerica(data: any): any {
        return data.split('/').reverse().join('-');
    }

    public static dateFormatterAmericaBrazil(data: any): any {

        let dataObject = new Date(data);
        
        let dia : any = dataObject.getDate();
        let mes : any = dataObject.getMonth();
        let ano : any = dataObject.getFullYear();

        if(dia < 10){
            dia = "0" + dia;
        }

        if(mes < 9){
            mes = "0" + (mes + 1);
        } else {
            mes = mes + 1;
        }

        let novaData = dia + "/" + mes + "/" + ano;

        return novaData;
    }

}