import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NavController, ToastController } from "@ionic/angular";
import { environment } from "../../environments/environment";
import { Login } from "../model/login";

@Injectable({ providedIn: "root" })
export class AutenticationService {
  public usuario: Login;

  constructor(
    private navCtrl: NavController,
    private http: HttpClient,
    private toastController: ToastController
  ) {
    this.usuario = JSON.parse(localStorage.getItem("data"));
  }

  public get logado(): Login {
    return this.usuario;
  }

  login(email: string, password: string) {
    let authorizationData =
      "Basic " + btoa(email.trim() + ":" + password.trim());

    const headerOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: authorizationData,
      }),
    };

    let userData = {
      email: email,
      senha: password,
    };

    this.http
      .post(`${environment.apiUrl}usuario/login`, userData, headerOptions)
      .subscribe(
        (data) => {
          // json data
          this.carregarToken(data);
          this.navCtrl.navigateRoot(["/home"]);
          this.sucesso();
        },
        (error) => {
          this.falha(error.message);
        }
      );
  }

  esqueciMinhaSenha(email: string) {
    const headerOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    let userData = {
      email: email,
    };

    this.http
      .post(
        `${environment.apiUrl}usuario/esquecimento`,
        userData,
        headerOptions
      )
      .subscribe(
        (data: any) => {
          // json data
          this.mensagem(data.mensagem);
        },
        (error) => {
          this.falha(error.message);
        }
      );
  }

  carregarToken(data) {
    var login: Login;
    login = data;
    localStorage.setItem("login", JSON.stringify(data));
    localStorage.setItem("token", JSON.stringify(login.token));
    localStorage.setItem("data", JSON.stringify(login.user));
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("data");
    localStorage.removeItem("token");
    localStorage.removeItem("login");
    this.navCtrl.navigateRoot(["/login"]);
  }

  async sucesso() {
    var a = this.toastController;
    const msg = await a.create({
      message: "Seja bem vindo ao Futplace!",
      duration: 3000,
      color: "primary",
    });
    msg.present();
  }

  async falha(erro) {
    var a = this.toastController;
    const msg = await a.create({
      message: "E-mail e/ou senha inválido(s) !",
      position: "top",
      duration: 3000,
      color: "danger",
    });
    msg.present();
  }

  async mensagem(msg) {
    var a = this.toastController;
    const msgExibida = await a.create({
      message: msg,
      position: "top",
      duration: 3000,
      color: "primary",
    });
    msgExibida.present();
  }
}
