export class AgendamentoUtil {
  static getDiaDaSemana(data: string) {
    let newDate = new Date(data);
    return newDate.getDay();
  }

  static getPosicaoTurno(hora: string) {
    if (Number(hora.substring(0, 2)) <= 11) {
      return 0;
    } else if (Number(hora.substring(0, 2)) <= 17) {
      return 1;
    } else {
      return 2;
    }
  }

  static getHoraTermino(hora: string, duracao: number) {
    if (hora.includes("30")) {
      return Number(hora.substring(0, 2)) + duracao + ":" + "30";
    } else {
      return Number(hora.substring(0, 2)) + duracao + ":" + "00";
    }
  }

  static getTipodeReserva(tipo: number) {
    return tipo == 0 ? "Diária" : "Mensal";
  }

  static getDataReservaFormatada(data: string) {
    let dataReserva = data.substring(0, 10);
    return (
      dataReserva.substring(8, 10) +
      "/" +
      dataReserva.substring(5, 7) +
      "/" +
      dataReserva.substring(0, 4)
    );
  }

  static generateIdAgendamento() {
    var charSet =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var randomString = "";
    for (var i = 0; i < 20; i++) {
      var randomPoz = Math.floor(Math.random() * charSet.length);
      randomString += charSet.substring(randomPoz, randomPoz + 1);
    }
    return randomString;
  }

  static compareDate(a, b) {
    return new Date(a).getTime() - new Date(b).getTime();
  }

  static arrayHorarios() {
    return [
      "08:00",
      "08:30",
      "09:00",
      "09:30",
      "10:00",
      "10:30",
      "11:00",
      "11:30",
      "12:00",
      "12:30",
      "13:00",
      "13:30",
      "14:00",
      "14:30",
      "15:00",
      "15:30",
      "16:00",
      "16:30",
      "17:00",
      "17:30",
      "18:00",
      "18:30",
      "19:00",
      "19:30",
      "20:00",
      "20:30",
      "21:00",
      "21:30",
      "22:00",
      "22:30",
      "23:00",
      "23:30",
    ];
  }

  static tConvert(time) {
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
  }

  static getMonthlyWeekNumber(dt) {
    // como função interna, permite reuso
    var getmonweek = function (myDate) {
      var today = new Date(
        myDate.getFullYear(),
        myDate.getMonth(),
        myDate.getDate(),
        0,
        0,
        0
      );
      var first_of_month = new Date(
        myDate.getFullYear(),
        myDate.getMonth(),
        1,
        0,
        0,
        0
      );
      var p = Math.floor(
        (today.getTime() - first_of_month.getTime()) / 1000 / 60 / 60 / 24 / 7
      );
      // ajuste de contagem
      if (today.getDay() < first_of_month.getDay()) ++p;
      // ISO 8601.
      if (first_of_month.getDay() <= 3) p++;
      return p;
    };
    // último dia do mês
    var udm = new Date(
      dt.getFullYear(),
      dt.getMonth() + 1,
      0,
      0,
      0,
      0
    ).getDate();
    /*  Nos seis primeiros dias de um mês: verifica se estamos antes do primeiro Domingo.
     *  Caso positivo, usa o último dia do mês anterior para o cálculo.
     */
    if (dt.getDate() < 7 && dt.getDate() - dt.getDay() < -2)
      return getmonweek(new Date(dt.getFullYear(), dt.getMonth(), 0));
    /*  Nos seis últimos dias de um mês: verifica se estamos dentro ou depois do último Domingo.
     *  Caso positivo, retorna 1 "de pronto".
     */ else if (dt.getDate() > udm - 6 && dt.getDate() - dt.getDay() > udm - 3)
      return 1;
    else return getmonweek(dt);
  }

  static qtddDiasNoMes(mes: any, ano: any) {
    let data = new Date(ano, mes, 0);
    return data.getDate();
  }

  static qttdSemanasDoMes(mes: any, ano: any) {
    let primeiroDiaDoMes = new Date(ano, mes, 1);

    /*Caso o mês tenha 28 dias (fevereiro) */
    if (AgendamentoUtil.qtddDiasNoMes(mes, ano) <= 28)
      if (primeiroDiaDoMes.getDay() == 0)
        /*Se o primeiro dia do mês for domingo  */
        return 4;
      else return 5;
    /*Caso o mês tenha 30 dias  */ else if (
      AgendamentoUtil.qtddDiasNoMes(mes, ano) == 30
    )
      if (primeiroDiaDoMes.getDay() == 6)
        /*Se o primeiro dia do mês for sábado  */
        return 6;
      else return 5;
    /*Caso o mês tenha 31 dias  */ else if (
      AgendamentoUtil.qtddDiasNoMes(mes, ano) == 31
    )
      if (primeiroDiaDoMes.getDay() == 5 || primeiroDiaDoMes.getDay() == 6)
        /*Se o primeiro dia do mês for sexta ou sábado  */
        return 6;
      else return 5;
    else return 5;
  }

  static dataHoje() {
    const options = {
      timeZone: "America/Sao_Paulo",
      day: "numeric",
      month: "numeric",
      year: "numeric",
    };
    const date = new Intl.DateTimeFormat([], options);
    return date.format(new Date());
  }

  static dateBrazil() {
    //MM/DD/AAAA
    let dataHojeEUA = this.dataHoje();

    let diaHojeBrazil = dataHojeEUA.substr(3, 2);
    let mesHojeBrazil = dataHojeEUA.substr(0, 2);

    return dataHojeEUA
      .substr(6)
      .concat("-")
      .concat(diaHojeBrazil)
      .concat("-")
      .concat(mesHojeBrazil);
  }

  static dateIsoStringToDate(data: any) {
    let ano = data.substr(0, 4);
    let mes = data.substr(5, 2);
    let dia = data.substr(8, 2);
    let hora = data.substr(11, 2);
    let min = data.substr(14, 2);
    return new Date(ano, mes - 1, dia, hora, min, 0);
  }

  static dateStringToIso(data: any) {
    let ano = data.substr(6, 4);
    let mes = data.substr(3, 2);
    let dia = data.substr(0, 2);
    let hora = data.substr(11, 2);
    let min = data.substr(14, 2);
    return new Date(ano, mes - 1, dia, hora - 3, min, 0).toISOString();
  }

  static dateComHoraDefinida(hora, min) {
    let dataHoje = new Date();
    return new Date(
      dataHoje.getFullYear(),
      dataHoje.getMonth() - 1,
      dataHoje.getUTCDate(),
      hora,
      min,
      0
    );
  }
}
