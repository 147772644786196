import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-detalhe-campo',
  templateUrl: './detalhe-campo.page.html',
  styleUrls: ['./detalhe-campo.page.scss'],
})
export class DetalheCampoPage implements OnInit {

  urlDetalheCampo:string

  constructor(private modalController: ModalController, private navParams: NavParams) {
    this.urlDetalheCampo = this.navParams.get('urlDetalheCampo');

  }

  ngOnInit() {
  }

  closeModal() {
    this.modalController.dismiss();
  }

}
