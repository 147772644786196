import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { AlertController, NavController } from '@ionic/angular';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivateChild {
    constructor(
        private navCtrl: NavController,
        private alertCtrl: AlertController
    ) { }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        
        var user = JSON.parse(localStorage.getItem('data'));

        if (user) {
            // logged in so return true
            return true;
        } else {
            this.presentAlertConfirm();
            return false;
        }
        // not logged in so redirect to login page with the return url
        //this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        
    }

    async presentAlertConfirm() {
        const alert = await this.alertCtrl.create({
            cssClass: 'aviso-login',
            message: 'Para visualizar o conteúdo desta página, será necessário acessar sua conta.',
            buttons: [
                {
                    text: 'Entrar',
                    cssClass: 'botao',
                    handler: () => {
                        this.navCtrl.navigateRoot(['/login']);
                    }
                }, {
                    text: 'Cadastrar',
                    cssClass: 'botao',
                    handler: () => {
                        this.navCtrl.navigateRoot(['/cadastro']);
                    }
                },
                {
                    text: 'Voltar',
                    cssClass: 'botao',
                    handler: () => {
                        this.navCtrl.navigateRoot(['/home']);
                    }
                }
            ]
        });

        await alert.present();
    }

}